import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "./propertyDetail.css";
import { Button, Skeleton, Typography } from "@mui/material";
import SUCCESSTICK from "../Assets/images/success-tick.png";
import GREENSUCCESSTICK from "../Assets/images/green-success-tick.svg";
import PDFICON from "../Assets/images/pdf.png";
import { useEffect, useState } from "react";
import apis from "../Services/api-services";
import { numDifferentiation } from "../Utils/helperUtils";
import { formatMessage } from "../constants/enums";
import { useNavigate } from "react-router-dom";
import {
  DECLARATION,
  IMAGE_VERIFICATION,
  MESSAGES,
  PROFILE_ONBOARDING,
} from "../constants/route-path";
import PropertyVerification from "./property-verification-modal";
import LoaderComponent from "./loader";
import { IconX } from "@tabler/icons-react";

const SAVED = "saved";
const HOME = "home";
const LISTING = "listing";
const UNLOCK = "unlock";
const PROFILE = "profile";

export default function PropertyDetail(props) {
  const {
    toggleDrawer,
    open,
    selectedPropertyID,
    from,
    fromMsg = false,
    externalAgentId = "",
    fetchListedProperties,
    setOpenProfile,
  } = props;

  const [selectedproperty, setSelectedProperty] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeImg, setActiveImg] = useState("");
  const [showFullText, setShowFullText] = useState(false);
  const [chatUnlocked, setChatUnlocked] = useState(false);
  const [verificationIniated, setVerificationIniated] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [brokerStatus, setBrokerStatus] = useState({});

  const resetData = () => {
    setVerificationSuccess(false);
    setVideoPreview(null);
    setVideoFile(null);
    setVerificationIniated(false);
  };

  useEffect(() => {
    if (from === LISTING) {
      setLoading(true);

      apis.agent
        .getAgentProfileCompletionStatus()
        .then((res) => {
          setLoading(false);

          const profile = res?.data?.profile;
          setBrokerStatus(profile);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }

    return () => {
      resetData();
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPropertyID) {
      setLoading(true);
      if (from === SAVED) {
        apis.property
          .getSavedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              coverImg
                ? setActiveImg(coverImg)
                : setActiveImg(property?.files[0]);
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else if (from === UNLOCK) {
        apis.property
          .getUnlockedPropertiesByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              coverImg
                ? setActiveImg(coverImg)
                : setActiveImg(property?.files[0]);
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else if (from === HOME) {
        apis.property
          .getRecomendedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              coverImg
                ? setActiveImg(coverImg)
                : setActiveImg(property?.files[0]);
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else if (from === PROFILE && externalAgentId) {
        apis.agent
          .getAgentPropertyDetailsById({
            agentId: externalAgentId,
            propertyId: selectedPropertyID,
          })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              coverImg
                ? setActiveImg(coverImg)
                : setActiveImg(property?.files[0]);
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      } else {
        apis.property
          .getListingPropertiesByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              coverImg
                ? setActiveImg(coverImg)
                : setActiveImg(property?.files[0]);
            }
            setSelectedProperty(property);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching property by ID", err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyID]);

  const handlePdfClick = (pdf) => {
    window.open(pdf, "_blank");
  };

  const handleUnlockChat = () => {
    let obj = {
      chat_room: null,
      cover_picture: activeImg.file_url,
      owner_name: selectedproperty.created_by,
      property_title: selectedproperty.property_title,
      property_id: selectedproperty.property_id,
      broker_id: selectedproperty.broker_id,
    };
    setChatUnlocked(true);
    setTimeout(() => {
      navigate(MESSAGES, { state: obj });
      externalAgentId && navigate(0);
    }, 2500);
  };

  const handleMessageClick = () => {
    let obj = {
      chat_room: selectedproperty.chat_room,
      cover_picture: activeImg.file_url,
      owner_name: selectedproperty.created_by,
      property_title: selectedproperty.property_title,
      broker_id: selectedproperty.broker_id,
      property_id: {},
    };
    navigate(MESSAGES, { state: obj });
  };

  const handleVerifyProperty = () => {
    if (brokerStatus?.is_verified) {
      if (verificationIniated) {
        setUploadLoader(true);
        const formData = new FormData();
        formData.append("video_verification", videoFile);
        apis.property
          .verifyProperty(formData, selectedproperty.property_id)
          .then((response) => {
            setUploadLoader(false);
            setVideoFile(null);
            setVideoPreview(null);
            setVerificationSuccess(true);
          })
          .catch((err) => {
            setUploadLoader(false);
            console.log(err, "Error Uploading Video - Property Verification");
          });
      } else {
        setVerificationIniated(true);
      }
    } else if (
      brokerStatus?.is_part1_complete &&
      !brokerStatus?.is_ekyc_completed
    ) {
      navigate(DECLARATION);
    } else if (
      brokerStatus?.is_ekyc_completed &&
      !brokerStatus?.is_verification_inititated
    ) {
      navigate(IMAGE_VERIFICATION);
    } else {
      navigate(PROFILE_ONBOARDING);
    }
  };

  const handleVerificationClose = () => {
    setVerificationIniated(false);
    fetchListedProperties();
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          id="property-detail"
          sx={{ width: 645 }}
          role="presentation"
          className="property-detail-container"
        >
          {!verificationIniated && (
            <span
              className="property-detail-close"
              onClick={toggleDrawer(false)}
            >
              <IconX className="property-detail-close-icon" />
            </span>
          )}

          {verificationIniated && (
            <PropertyVerification
              videoFile={videoFile}
              setVideoFile={setVideoFile}
              videoPreview={videoPreview}
              setVideoPreview={setVideoPreview}
              onClose={() => handleVerificationClose()}
              verificationSuccess={verificationSuccess}
              verificationCode={selectedproperty?.prop_id}
            />
          )}

          {loading ? (
            <Box sx={{ margin: 3 }}>
              <Skeleton height={300} />
              <Skeleton height={200} />
              <Skeleton height={300} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            !verificationIniated && (
              <div className="property-detail-content" id="property-detail">
                <div className="property-info-postedBy">
                  <div
                    className="property-info-agent-details"
                    onClick={() => from !== LISTING && setOpenProfile(true)}
                  >
                    <div className="agent-profile-details">
                      <img
                        src={selectedproperty?.profile_picture}
                        alt="profile"
                        className="agent-profile-icon"
                      />
                    </div>
                    <div className="agent-details">
                      <span className="agent-details-posted-by-text">
                        Posted by:
                      </span>
                      <span className="agent-name">
                        {selectedproperty?.created_by}{" "}
                        {selectedproperty?.is_verified && (
                          <img
                            src={SUCCESSTICK}
                            alt="profile"
                            className="agent-verified-icon"
                          />
                        )}
                      </span>
                      {/* <span className="agent-rating">
                          <img
                            src={STAR}
                            alt="profile"
                            className="agent-star"
                          />{" "}
                          {selectedproperty?.agent_ratings} |{" "}
                          <span className="agent-reviews">{selectedproperty?.agent_reviews} Reviews</span>
                        </span> */}
                    </div>
                  </div>
                </div>
                {chatUnlocked ? (
                  <div className="chat-unlocked-container">
                    <div className="chat-unlocked-content">
                      <img
                        src={GREENSUCCESSTICK}
                        className="chat-unlocked-success-tick-icon"
                        alt="success-tick"
                      />
                      <span className="chat-unlocked-title">
                        Chat Unlocked!
                      </span>
                      <span className="chat-unlocked-subtitle">
                        Chat to know more about the property
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedproperty?.files &&
                      selectedproperty?.files.length > 0 && (
                        <div className="property-images">
                          <div className="property-active-image-container">
                            <img
                              src={activeImg?.file_url}
                              alt="active-image"
                              className="active-property-img"
                            />
                          </div>
                          {selectedproperty?.files.length > 1 && (
                            <div className="property-images-list">
                              {selectedproperty?.files.map((item, index) => (
                                <div
                                  key={item.file_id}
                                  className="property-image-box"
                                  onClick={() => setActiveImg(item)}
                                >
                                  <img
                                    src={item.file_url}
                                    title={item.file_name}
                                    alt="property-img"
                                    className="property-img"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                    <div className="property-info">
                      <div className="property-info-stats">
                        {/* <div className="property-views-info">
                    <img src={VIEWICON} alt="view" className="views-icon" />
                    <span className="property-views-text">{selectedproperty?.views} Views</span>
                  </div> */}
                      </div>
                      <div className="property-info-details">
                        <div className="property-info-details-list">
                          <span className="property-info-title">
                            {selectedproperty?.property_title}{" "}
                            {selectedproperty?.property_status ===
                              "Verified" && (
                              <img
                                src={SUCCESSTICK}
                                alt="view"
                                className="success-tick-icon"
                              />
                            )}
                          </span>
                          <span className="property-info-location">
                            {selectedproperty?.locality},{" "}
                            {selectedproperty?.city}
                          </span>
                          <span className="property-info-subtitle">
                            {selectedproperty?.property_type} |{" "}
                            {selectedproperty?.furnish_type} |{" "}
                            {selectedproperty?.built_up_area} sq.ft.
                          </span>
                        </div>
                        <div className="property-price-value">
                          <span className="property-value">
                            {selectedproperty?.sale_price
                              ? numDifferentiation(selectedproperty?.sale_price)
                              : numDifferentiation(
                                  selectedproperty?.rent_amount
                                )}
                            {selectedproperty?.property_sale_type === "Rent" &&
                              " p.m"}
                          </span>
                        </div>
                      </div>
                      <div className="property-info-rooms">
                        <div className="property-rooms">
                          <span className="property-room-count">
                            {selectedproperty?.bedroom_count || 0}
                          </span>
                          <span className="property-room-type">Bedrooms</span>
                        </div>
                        <div className="property-rooms">
                          <span className="property-room-count">
                            {selectedproperty?.bathroom_count || 0}
                          </span>
                          <span className="property-room-type">Bathrooms</span>
                        </div>
                        <div className="property-rooms">
                          <span className="property-room-count">
                            {selectedproperty?.balcony_count || 0}
                          </span>
                          <span className="property-room-type">Balcony</span>
                        </div>
                      </div>
                    </div>
                    {selectedproperty?.property_description && (
                      <div className="property-overview property-box-border">
                        <div className="property-overview-title">
                          Description
                        </div>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginTop: "16px",
                            display: "-webkit-box",
                            WebkitLineClamp: showFullText ? "none" : 5,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "0px 12px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatMessage(
                              selectedproperty?.property_description
                            ),
                          }}
                        />
                        <Button
                          onClick={() => setShowFullText(!showFullText)}
                          sx={{
                            fontSize: "14px",
                            textTransform: "lowercase",
                            textDecoration: "underline",
                            margin: "0px 4px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {showFullText ? "read less" : "read more"}
                        </Button>
                      </div>
                    )}

                    <div className="property-overview property-box-border">
                      <div className="property-overview-title">Overview</div>
                      {selectedproperty?.bedroom_count && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Bedrooms
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.bedroom_count}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.bathroom_count && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Bathrooms
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.bathroom_count}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.balcony_count && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Balconies
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.balcony_count}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.property_type && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Property Type
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.property_type}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.furnish_type && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Furnishing
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.furnish_type}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.rent_amount && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Rent Amount
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.rent_amount}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.built_up_area && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Built-up Area
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.built_up_area}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.carpet_area && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Carpet Area
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.carpet_area}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.property_on_floor && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Property on Floor
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.property_on_floor}{" "}
                            {selectedproperty?.total_no_of_floors
                              ? `of ${selectedproperty?.total_no_of_floors}`
                              : ""}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.occupancy_status && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Occupancy Status
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.occupancy_status}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.property_facing && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Property Facing
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.property_facing}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.property_established && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Built in
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.property_established}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.residential_parking && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">
                            Car Parking
                          </span>
                          <span className="property-overview-value">
                            {selectedproperty?.residential_parking}
                          </span>
                        </div>
                      )}
                      {selectedproperty?.lift && (
                        <div className="property-overview-items">
                          <span className="property-overview-label">Lifts</span>
                          <span className="property-overview-value">
                            {selectedproperty?.lift}
                          </span>
                        </div>
                      )}
                    </div>
                    {selectedproperty?.amenities?.length > 0 && (
                      <div className="property-amenties">
                        <div className="property-overview property-box-border">
                          <div className="property-overview-title">
                            Amenities
                          </div>
                          {selectedproperty?.amenities?.map((item) => (
                            <div className="property-amenties-items">
                              <span className="property-amenties-value">
                                {item}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {selectedproperty?.property_sale_type === "Rent" && (
                      <div className=" property-rent-details">
                        {selectedproperty?.security_deposit && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              Security Deposit
                            </span>
                            <span className="property-rent-details-value">
                              ₹ {selectedproperty?.security_deposit}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.maintenance_amount && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              Maintenance Amount
                            </span>
                            <span className="property-rent-details-value">
                              ₹ {selectedproperty?.maintenance_amount}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.lock_in_period && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              Lock-in Period
                            </span>
                            <span className="property-rent-details-value">
                              {selectedproperty?.lock_in_period}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.tenant_preferences?.length > 0 && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              Tenant Preferences
                            </span>
                            {selectedproperty?.tenant_preferences?.map(
                              (item, index) => (
                                <span className="property-rent-details-value">
                                  {item}{" "}
                                  {index <
                                  selectedproperty?.tenant_preferences?.length
                                    ? ","
                                    : ""}
                                </span>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {selectedproperty?.documents?.length > 0 && (
                      <div className="property-docs">
                        <div className="property-overview property-box-border">
                          <div className="property-overview-title">
                            Document
                          </div>
                          {selectedproperty?.documents?.map((item) => (
                            <div
                              className="property-docs-items property-box-border"
                              onClick={() => handlePdfClick(item.file_url)}
                            >
                              <img src={PDFICON} alt="pdf-icon" width={30} />
                              <span className="property-doc-name">
                                {item.file_name}
                              </span>
                              <span className="property-doc-size">
                                {item.file_size}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* <div className='property-liv-score property-box-border'>
            <div className='property-overview-title'>Liveability Score</div>
            <CircularProgress color='success' variant="determinate" value={55} />
           </div> */}
                  </>
                )}
              </div>
            )
          )}

          {(from === HOME || from === SAVED || externalAgentId) &&
            !chatUnlocked && (
              <div className="property-detail-footer">
                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="property-detail-btn"
                    color="primary"
                    onClick={handleUnlockChat}
                  >
                    Unlock to chat
                  </Button>
                )}
              </div>
            )}

          {from === UNLOCK && !fromMsg && (
            <div className="property-detail-footer">
              {loading ? (
                <Skeleton width={100} height={50} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="property-detail-btn"
                  color="primary"
                  onClick={handleMessageClick}
                >
                  Message
                </Button>
              )}
            </div>
          )}

          {from === LISTING &&
            !verificationSuccess &&
            selectedproperty?.property_status !== "Verified" && (
              <div className="property-detail-footer">
                {verificationIniated && (
                  <Typography
                    color={"#A03C42"}
                    sx={{ mb: 1, fontSize: "12px", textAlign: "center" }}
                  >
                    *Failure to follow these guidelines will result in failed
                    verification.
                  </Typography>
                )}

                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : (
                  !verificationIniated &&
                  selectedproperty?.property_status === "Rejected" && (
                    <>
                      <Typography
                        color={"#A03C42"}
                        sx={{
                          mb: 1,
                          fontSize: "16px",
                          textAlign: "center",
                          width: "100%",
                          background: "#FFF0CD",
                        }}
                      >
                        Property Rejected
                      </Typography>

                      {selectedproperty?.rejection_reason?.length > 0 &&
                        selectedproperty?.rejection_reason?.map((item) => {
                          return (
                            <Typography
                              color={"#A03C42"}
                              sx={{
                                mb: 1,
                                fontSize: "12px",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              *{item}
                            </Typography>
                          );
                        })}
                    </>
                  )
                )}

                {!brokerStatus?.is_verified &&
                  !brokerStatus?.is_verification_initiated && (
                    <Typography
                      sx={{ mb: 1, fontSize: "12px", textAlign: "center" }}
                    >
                      Please complete your profile verification to start the
                      property verification process.
                    </Typography>
                  )}

                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : selectedproperty?.property_status === "Initiated" ? (
                  <span className="verification-pending-msg">
                    Verification is pending. We’ll notify you about the status.
                  </span>
                ) : !brokerStatus?.is_verified &&
                  brokerStatus?.is_verification_initiated ? (
                  <Typography
                    sx={{ mb: 1, py: 2, fontSize: "14px", textAlign: "center" }}
                  >
                    Your profile verification is in progress, We will let you
                    know once the profile is verified.
                  </Typography>
                ) : (
                  selectedproperty?.property_status !== "Rejected" && (
                    <Button
                      type="submit"
                      variant="contained"
                      className="property-detail-btn"
                      color="primary"
                      disabled={verificationIniated && !videoFile}
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleVerifyProperty}
                    >
                      {verificationIniated ? "Submit" : "Verify Now"}
                    </Button>
                  )
                )}
              </div>
            )}
        </Box>
      </Drawer>
      {uploadLoader && <LoaderComponent />}
    </div>
  );
}
