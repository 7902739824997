import React, { useCallback, useEffect, useRef, useState } from "react";
import AIRARECT from "../../Assets/images/Aira_Logo_purple.png";
import HERO from "../../Assets/images/home-background.png";
import HERO2 from "../../Assets/images/home-background2.png";
import SEARCHICON from "../../Assets/images/search-icon.png";
import PROFILE from "../../Assets/images/nav-profile.png";
import NOTIFY from "../../Assets/images/nav-nofication.png";
import READYTOLIST from "../../Assets/images/ready-to-list.svg";
import LOGOUT_ICON from "../../Assets/images/logout.svg";
import "./home.css";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  Slider,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropertyList from "../../components/propertyList";
import apis from "../../Services/api-services";
import { useNavigate } from "react-router-dom";
import { CHAT_WITH_AIRA, PROFILE_ONBOARDING } from "../../constants/route-path";
import {
  createQueryURl,
  numDifferentiation,
  sanitizeData,
} from "../../Utils/helperUtils";
import HomeSidebar from "../../components/home-sidebar";
import LoaderComponent from "../../components/loader";
import {
  GOOGLE_MAPS_API_KEY,
  maxRentValue,
  maxSaleValue,
  minRentValue,
  minSaleValue,
  RENTVALUE,
  SALEVALUE,
} from "../../constants/app-constants";
import { IconUserScan } from "@tabler/icons-react";
import { handleProfileRedirection } from "../../constants/enums";
import InfoModal from "../Broker/CreateProfile/components/InfoModal";
import { Loader } from "@googlemaps/js-api-loader";

const newDesign = true;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const RenderDropDown = ({
  Label,
  list = [],
  value,
  setState,
  placeholder,
  addClasses,
  multiple = false,
  disabled = false,
}) => {
  const handleChange = (event) => {
    setState(event.target.value);
  };
  return (
    <div className={`render-dropdown-main ${addClasses || ""}`}>
      {/* <span className={`render-dropdown-label `}>{Label}</span> */}

      <FormControl
        className={`render-drop-down-input `}
        sx={{
          m: 1,
          mr: 2,
          width: "90%",
          borderRadius: "8px",
          background: "rgba(244, 244, 244, 1)",
          border: "none",
          "& fieldset": {
            border: "none",
            fontSize: "10px",
          },
          "& .MuiOutlinedInput-input": {
            fontSize: "14px",
          },
        }}
        size="small"
      >
        {multiple && Array.isArray(value) ? (
          <Select
            multiple
            displayEmpty
            value={value}
            disabled={disabled}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <>{Label}</>;
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <>{Label}</>
            </MenuItem>
            {list.map((item) => (
              <MenuItem
                key={item.id}
                value={item.value}
                // style={getStyles(name, personName, theme)}
              >
                {item.value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select value={value} onChange={handleChange} autoWidth={false}>
            <MenuItem value={"null"} disabled>
              {Label}
            </MenuItem>
            {list.map((item) => {
              return <MenuItem value={item.value}>{item.value}</MenuItem>;
            })}
          </Select>
        )}
      </FormControl>
    </div>
  );
};

const Home = () => {
  const propertyRef = useRef();
  const navigate = useNavigate();
  const [propertyList, setPropertyList] = useState([]);
  const [dropDownValues, setDropDownValue] = useState({});
  const [propertyType, setPropertyType] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [houseType, setHouseType] = useState([]);
  const [furnishType, setFurnishType] = useState([]);
  const [bedrooms, setBedRooms] = useState([]);
  const [location, setLocation] = useState("");
  // const [selectedLocation,setSelecttedLocation] =  useState('')
  const [budgetRange, setbudgetRange] = useState([20000, 50000000]);
  const [matchCount, setMatchCount] = useState(0);
  const [matchStatus, setMatchStatus] = useState(true);
  const [minBuildUp, setMinBuildUp] = useState();
  const [maxBuildUp, setMaxBuildUp] = useState();
  const [availabilityDate, setAvailabilityDate] = useState();
  const [showPropertyList, setShowPropertyList] = useState(false);
  const [bedRoomsDisable, setBedRoomsDisable] = useState(false);
  const [isVerifiedBroker, setIsVerifiedBroker] = useState(true);
  const [profileDetails, setProfileDetails] = useState();
  const [openCompleteVerificationModal, setOpenCompleteVerificationModal] =
    useState(false);

  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);
  const debounceRef = useRef(null);

  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const [minBudget,setMinBudget] =  useState(minRentValue)
  // const [maxBudget,setMaxBudget] =  useState(maxRentValue)

  const handleChange = (event, newValue) => {
    setbudgetRange(newValue);
  };
  function valuetext(value) {
    return `${value}`;
  }

  const propertyProps = {
    dropDownValues,
    propertyType,
    setPropertyType,
    houseType,
    setHouseType,
    furnishType,
    setFurnishType,
    bedrooms,
    setBedRooms,
    location,
    setLocation,
    budgetRange,
    setbudgetRange,
    setPropertyList,
    selectedCity,
    setSelectedCity,
    loader,
    setLoader,
    matchCount,
    setMatchCount,
    matchStatus,
    setMatchStatus,
    minBuildUp,
    setMinBuildUp,
    maxBuildUp,
    setMaxBuildUp,
    availabilityDate,
    setAvailabilityDate,
    options,
    loading,
  };

  useEffect(() => {
    checkIfUserCompletedVerification();
  }, []);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();

      const map = new window.google.maps.Map(document.createElement("div"));
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        map
      );

      // handlePinLocationMap();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>{
  //   if(houseType === 'Rent'){
  //     setMinBudget(minRentValue)
  //     setMaxBudget(maxRentValue)
  //   }else if(houseType=== 'Sale'){
  //     setMinBudget(minSaleValue)
  //     setMaxBudget(maxSaleValue)
  //   }
  // },[houseType])

  // const buildQueryParams = (params) => {
  //   return Object.keys(params)
  //     .filter((key) => params[key]) // Filter out empty values
  //     .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  //     .join('&');
  // };

  // const fetchFilteredData = async (filters) => {
  //   const baseUrl = 'https://aira-apis.indiassetz.com/v1/user/recommendations';
  //   const queryParams = buildQueryParams(filters);
  // };

  useEffect(() => {
    setLoader(true);
    apis.property
      .getDropDownValues()
      .then((response) => {
        setLoader(false);
        setDropDownValue(response.data);
        console.log(response.data, "ressss");
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error fetching dropdown values", err);
      });
  }, []);

  useEffect(() => {
    if (propertyType.includes(SALEVALUE) && propertyType.includes(RENTVALUE)) {
      setbudgetRange([10000, 500000000]);
    } else if (propertyType.includes(SALEVALUE)) {
      setbudgetRange([1500000, 500000000]);
    } else if (propertyType.includes(RENTVALUE)) {
      setbudgetRange([10000, 500000]);
    }
  }, [propertyType]);

  const handleHouseTypevalidation = (data) => {
    if (
      data.includes("Warehouse") ||
      data.includes("Showroom") ||
      data.includes("Shop") ||
      data.includes("Office")
    ) {
      setBedRooms([]);
    }
  };

  const handlePropertyDisplay = () => {
    setShowPropertyList(true);
    setLoader(true);
    const filters = {
      ...(sanitizeData(location) ? { locality: location } : {}),
      ...(sanitizeData(houseType) ? { property_type: houseType } : {}),
      ...(sanitizeData(bedrooms) ? { bedroom: bedrooms } : {}),
      ...(sanitizeData(furnishType) ? { furnishing: furnishType } : {}),
      ...(sanitizeData(propertyType) ? { list_type: propertyType } : {}),
      ...(sanitizeData(selectedCity) ? { city: selectedCity } : {}),
      // minimum_built_up_area: 1200,
      // maximum_built_up_area: 2000,
      // availability: "2024-10-03",
      minimum_budget: budgetRange[0],
      maximum_budget: budgetRange[1],
    };

    apis.property
      .getRecommendations(createQueryURl(filters))
      .then((response) => {
        setLoader(false);

        setPropertyList(response.data.recommendations);
        setMatchCount(response.data.total_count);
        setMatchStatus(response.data.match_found);
      })
      .catch((err) => {
        setLoader(false);
      });

    // propertyRef.current?.scrollIntoView({behavior: 'smooth'});
    // setTimeout(() => {
    //   setShowHome(false)
    // }, 1000);
  };

  const handleHouseTypeSelection = (value) => {
    if (houseType.includes(value)) {
      let temparr = houseType.filter((item) => {
        return item !== value;
      });
      setHouseType(temparr);
    } else {
      handleHouseTypevalidation([...houseType, value]);
      setHouseType([...houseType, value]);
    }
  };

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPredictions = (input) => {
    if (!input || !autocompleteServiceRef.current) return;

    setLoading(true);

    autocompleteServiceRef.current.getPlacePredictions(
      {
        input,
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "in" },
      },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setOptions(predictions);
        } else {
          setOptions([]);
        }
        setLoading(false);
      }
    );
  };

  const handleInputChange = (e, val) => {
    setLocation(val);
    debouncedFetchPredictions(val);
  };

  const debouncedFetchPredictions = useCallback(
    debounce(fetchPredictions, 300),
    []
  );

  const getMinSliderValue = () => {
    if (propertyType.includes(SALEVALUE) && propertyType.includes(RENTVALUE)) {
      return minRentValue;
    } else if (propertyType.includes(SALEVALUE)) {
      return minSaleValue;
    } else {
      return minRentValue;
    }
  };

  const getMaxSliderValue = () => {
    if (propertyType.includes(SALEVALUE) && propertyType.includes(RENTVALUE)) {
      return maxSaleValue;
    } else if (propertyType.includes(RENTVALUE)) {
      return maxRentValue;
    } else {
      return maxSaleValue;
    }
  };

  const checkIfUserCompletedVerification = async () => {
    const { data: { profile } = {} } =
      await apis.agent.getAgentProfileCompletionStatus();

    if (!profile) return;
    setIsVerifiedBroker(profile?.is_verified);
    setProfileDetails(profile);

    const { is_part1_complete, is_ekyc_completed, is_verification_initiated } =
      profile;

    if (!is_verification_initiated) {
      setOpenCompleteVerificationModal(true);
    } else {
      return;
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}

      {!showPropertyList ? (
        <div className="home-container">
          <div className="home-new-container">
            <div className="home-new-container-heading">
              <span className="home-new-heading-title">
                List, Connect, and Grow.
              </span>
              <span className="home-new-heading-subtitle">
                Easily FIND or Showcase{" "}
                <span className="vrefyd-home-bold">vREfyd</span> Properties to
                Boost Your Transaction Speed.
              </span>
              {(!profileDetails?.is_ekyc_completed ||
                !profileDetails?.is_part1_complete ||
                !profileDetails?.is_verification_initiated) && (
                <span
                  className="home-ready-to-list"
                  onClick={() => handleProfileRedirection(navigate)}
                >
                  <img
                    src={READYTOLIST}
                    className="home-ready-to-list-icon"
                    alt="get-vrefyd"
                  />
                </span>
              )}

              {!isVerifiedBroker && (
                <span className="home-ready-to-list-info">
                  Only verified agents can publish properties for now. Get
                  verified to build trust and connect faster!
                </span>
              )}
            </div>
            <div className="home-new-container-inputs">
              <div className="home-new-row">
                <RenderDropDown
                  placeholder="Select City"
                  Label="City"
                  list={dropDownValues?.cities}
                  value={selectedCity}
                  setState={setSelectedCity}
                  addClasses={"w-1-2 mtop-5"}
                  multiple={true}
                />
                <Box className="home-search-input" sx={{ width: "50%" }}>
                  <Autocomplete
                    size="small"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                      marginTop: "13px",
                      borderRadius: "16px",
                      ".MuiOutlinedInput-root": {
                        borderRadius: "16px",
                      },
                    }}
                    freeSolo
                    options={options}
                    getOptionLabel={(option) => option.description || ""}
                    filterOptions={(x) => x} // Disable default Material UI filter to show all options
                    loading={loading}
                    inputValue={location}
                    onInputChange={handleInputChange}
                    onChange={(e, val) => {
                      if (val) {
                        // getPlaceLatLong(val?.place_id);
                        const address = val.description?.split(",");
                        if(val.description){
                          setLocation(val.description);
                        }
                        // setFieldValue('apartmentOrSocietyName', address[0] || address[1]);
                        // setFieldValue('roadOrArea', address[1] || address[2]);
                        // setFieldValue('state', address[address?.length - 2].trim());
                        // setFieldValue('city', address[address?.length - 3]?.trim());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for Locality"
                        variant="outlined"
                        sx={{
                          borderColor: "#474747",
                        }}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              <span onClick={() => setLocation("")}>
                                {params.InputProps.endAdornment}
                              </span>
                            </>
                          ),

                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <SearchIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </div>
              {dropDownValues?.property_types?.length && (
                <div className="home-new-row">
                  <span className="render-dropdown-label">Type</span>
                  <div className="home-new-house-list">
                    {dropDownValues?.property_types?.map((item) => {
                      return (
                        <span
                          onClick={() => handleHouseTypeSelection(item.value)}
                          className={`home-filter-house-values ${
                            houseType?.includes(item.value)
                              ? "active-selected-house-type"
                              : ""
                          }`}
                        >
                          {item.value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="home-new-row">
                <RenderDropDown
                  placeholder="Select Type"
                  Label=" Purchase/Rent"
                  list={dropDownValues?.new_list_type}
                  value={propertyType}
                  setState={setPropertyType}
                  addClasses={"w-1-2 mtop-5"}
                  multiple={true}
                />
                <RenderDropDown
                  placeholder="Select Bedroom"
                  Label="Bedroom"
                  list={dropDownValues?.bedrooms}
                  value={bedrooms}
                  setState={setBedRooms}
                  addClasses={"w-1-2 mtop-5"}
                  multiple={true}
                  disabled={
                    houseType.includes("Warehouse") ||
                    houseType.includes("Showroom") ||
                    houseType.includes("Shop") ||
                    houseType.includes("Office")
                  }
                />
              </div>

              <div className="home-new-row">
                <span className="render-dropdown-label">Budget</span>
                <Box sx={{ width: "80%", marginX: 2, marginLeft: 3 }}>
                  <Slider
                    getAriaLabel={() => "Price Range"}
                    value={budgetRange}
                    onChange={handleChange}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    min={getMinSliderValue()}
                    max={getMaxSliderValue()}
                    valueLabelFormat={(value) => numDifferentiation(value)}
                    sx={{
                      width: "100%",
                      ".MuiSlider-thumb": {
                        width: "10px",
                        height: "10px",
                      },
                      ".MuiSlider-valueLabel": {
                        background: "#F5EDFF",
                        color: "#7D7D7D",
                        borderRadius: "30px",
                        top: 50,
                        "::before": {
                          // Adjust the arrow pointing direction
                          content: '""',
                          position: "absolute",
                          width: 0,
                          height: 0,
                          borderLeft: "7px solid #fff",
                          borderRight: "7px solid #fff",
                          borderTop: "7px solid #F5EDFF", // Arrow color
                          top: -6, // This adjusts the arrow's position to point upwards
                          left: "50%",
                          transform: "translateX(-50%) rotate(180deg)",
                        },
                      },
                    }}
                  />
                </Box>
              </div>
              <div className="home-new-search-btn">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={selectedCity.length < 1 || propertyType.length < 1}
                  sx={{
                    height: "100%",
                    textTransform: "none",
                    paddingX: 5,
                    marginTop: 1,
                  }}
                  onClick={() => handlePropertyDisplay()}
                >
                  Find
                </Button>
              </div>
            </div>
            {/* </div> */}
            <span className="home-footer">
              <span className="home-footer-aira-text">AIRA</span> is an
              Artificially Intelligent Real Estate Agent - Discover properties,
              manage transactions, and gain real estate insights with ease.
            </span>
          </div>
          <div
            className="home-chat-aira-btn font-medium"
            onClick={() => navigate(CHAT_WITH_AIRA)}
          >
            <img src={AIRARECT} alt="aira-logo" className="home-aira-rect" />
            Ask AIRA
          </div>
        </div>
      ) : (
        <PropertyList
          from="home"
          data={propertyList}
          propertyRef={propertyRef}
          {...propertyProps}
          handleInputChange={handleInputChange}
        />
      )}

      <InfoModal
        open={openCompleteVerificationModal}
        setOpen={setOpenCompleteVerificationModal}
        title="Get vREfyd!"
        titleStyle={{
          color: "#600DB4",
          fontWeight: 900,
        }}
        description="In this phase, we are allowing only verified realtors to add
          properties to the vREfyd platform. To maintain trust and
          transparency, it is essential to complete the verification process.
          This ensures that all property listings come from genuine and verified
          sources."
        icon={<IconUserScan size={"42px"} color="#600DB4" />}
        primaryButtonText="Complete Verification"
        primaryButtonAction={() => handleProfileRedirection(navigate)}
      />
    </>
  );
};

export default Home;
