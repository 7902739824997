import React, { useState } from "react";
import SAVEICON from "../Assets/images/save-icon.png";
import UNSAVEICON from "../Assets/images/unsave-icon.png";
import SUCCESSTICK from "../Assets/images/success-tick.png";

import UNLOCK from "../Assets/images/unlock.png";
import { numDifferentiation } from "../Utils/helperUtils";
import apis from "../Services/api-services";
import { RENTVALUE } from "../constants/app-constants";

const PropertyListItem = (props) => {
  const {
    showDays,
    parentClasses = "",
    cover_picture,
    property_status,
    property_sale_type,
    property_title,
    locality,
    property_unlock_count,
    sale_price,
    rent_amount,
    built_up_area,
    bedroom_count,
    created_by,
    is_verified,
    profile_picture,
    fromSaved = false,
    handleSelectedProperty,
    property_id,
    from,
    broker_id,
    
  } = props;
  const [isSave, setIsSave] = useState(fromSaved);

  const getChipColor = (status) => {
    switch (status) {
      case 'Verified':
        return '#EBF4DF';
      case 'Failed':
        return '#F4E3DF';
      case 'Rejected':
        return '#F4E3DF';
      case 'Approval Pending':
        return '#F4E3DF';
      case 'Initiated':
        return '#F4F0DF';
      case 'Re-Applied':
        return '#F4F0DF';
      default:
        return '#E3E3E3';
    }
  };

  const handleWishList = () => {
    apis.property
      .wishList({ property_id }, !isSave ? "POST" : "DELETE")
      .then((response) => {
        console.log(response);

        setIsSave(!isSave);
      })
      .then((err) => {
        console.log(err, "Saving property");
      });
    setIsSave(!isSave);
  };

  return (
    <div
      className={`property-item ${parentClasses || ""}`}
      onClick={(e) => handleSelectedProperty(property_id, e.target, broker_id)}
    >
      {from !== "listing" && (
        <div className="property-item-postedby">
          <img
            src={profile_picture}
            className="property-item-profile-pic"
            alt="profile"
          />
          <span className="property-item-postedby-name">{created_by}</span>
          {is_verified && (
            <img
              src={SUCCESSTICK}
              className="property-item-verified-icon"
              alt="profile"
            />
          )}
        </div>
      )}
      <div className="property-item-image-container">
        <img
          src={cover_picture}
          alt="property-image"
          className="property-item-img"
        />
        {from !== "unlock" && (
          <>
            {showDays ? (
              <div className="show-days" style={{ background: getChipColor(property_status) }}>
                {property_status}
              </div>
            ) : (
              <div
                className="property-item-save"
                id="property-item-save"
                onClick={() => handleWishList()}
              >
                <img
                  src={isSave ? SAVEICON : UNSAVEICON}
                  alt="save"
                  id="property-item-save"
                  width={14}
                />
              </div>
            )}
          </>
        )}

        {from === "listing" || from === "home" ? (
          sale_price && (
            <div className="property-item-price">
              {sale_price
                ? numDifferentiation(sale_price)
                : numDifferentiation(rent_amount)}
              {property_sale_type === RENTVALUE && " p.m"}
            </div>
          )
        ) : (
          <div className="property-item-price lesser-width">
            {property_sale_type}
          </div>
        )}
      </div>
      <div className="property-item-name">
        <span
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {property_title}
          {property_status === 'Verified' && (
            <img
              src={SUCCESSTICK}
              className="property-item-verified-icon"
              alt="profile"
            />
          )}{" "}
        </span>
        {from !== "home" && (
          <>
            {showDays ? (
              <span className="property-item-views">
                <img src={UNLOCK} alt="view" className="property-item-icon" />
                <span>{property_unlock_count} Unlocks</span>
              </span>
            ) : (
              <span className="saved-sale-price-info">
                {sale_price
                  ? numDifferentiation(sale_price)
                  : numDifferentiation(rent_amount)}
                {property_sale_type === RENTVALUE && " p.m"}
              </span>
            )}
          </>
        )}
      </div>
      <div className="property-item-built-up">
        {numDifferentiation(built_up_area, true)} sq. ft. | {bedroom_count} BHK
        | {property_sale_type}
      </div>
      <div className="property-item-location">{locality}</div>
    </div>
  );
};

export default PropertyListItem;
