import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Avatar, Button, Card, CardContent, Typography } from '@mui/material';
import { Download, Share } from '@mui/icons-material';
import ImagesLinks from '../../../../Utils/ImagesLinks';
import VerifiedIcon from '@mui/icons-material/Verified';
import { QRCode } from 'react-qrcode-logo';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';

export default function GenerateQRModal({ open, setOpen, agentDetails }) {
  const {userName} = useSelector((state) => state.userDetails || {});
  const areasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  const handleQRDownload = async () => {
    const element = document.getElementById('downloadQRImage');
    const canvas = await html2canvas(element, { useCORS: true });
    const data = canvas.toDataURL('image/png', 1);
    downloadBase64Image(data);
  };

  const downloadBase64Image = (base64Image, fileName) => {
    const link = document.createElement('a');
    link.href = base64Image;
    link.download = `${userName || 'Agent'}-digital-card.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      id="downloadQRImage"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card
        sx={{
          borderRadius: '16px',
          textAlign: 'center',
          width: '420px',
        }}
      >
        {/* Header with Logo */}
        <Box
          sx={{
            backgroundColor: '#6A1B9A',
            padding: '.5rem 0',
            borderRadius: '16px 16px 0 0',
          }}
        >
          <img
            src={ImagesLinks.vrefydLogoWhite}
            alt="vREfyd Logo"
            style={{ width: '154px', marginRight: 8 }}
          />
        </Box>

        <CardContent sx={{ mt: 4 }}>
          <Avatar
            src={agentDetails?.profile_picture}
            alt="User"
            sx={{
              width: 130,
              height: 130,
              margin: '0 auto',
              border: '4px solid white',
            }}
          />

          {/* User Information */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              mt: 1,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {agentDetails?.full_name}

            {agentDetails?.is_verified && (
              <VerifiedIcon sx={{ color: '#600DB4', marginLeft: '.5rem',width:37 }} />
            )}
          </Typography>

          {agentDetails?.specialization && (
            <Typography
              variant="body2"
              sx={{ marginTop: '.5rem', color: '#474747' }}
            >
              Specializes in {agentDetails?.specialization?.join(', ')}
            </Typography>
          )}

          <Box
            sx={{
              textAlign: 'center',
              margin: '1.5rem 0',
            }}
          >
            {areasOfOperation?.map((area, index) => (
              <Typography
                key={index}
                sx={{ fontSize: '14px', color: '#474747', lineHeight: '1.8' }}
              >
                {area?.places?.join(' | ')} |
              </Typography>
            ))}
          </Box>

          {/* QR Code */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2rem 0',
            }}
          >
            {agentDetails?.qr_code && (
              <QRCode
                value={agentDetails?.qr_code}
                fgColor="#6A1B9A"
                qrStyle="dots"
                logoImage={ImagesLinks.vrefydQrLogo}
                removeQrCodeBehindLogo={true}
                ecLevel="L"
              />
            )}
          </Box>

          {/* Action Buttons */}
          {agentDetails?.qr_code && (
            <Box
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              data-html2canvas-ignore="true"
            >
              <Button
                variant="outlined"
                startIcon={<Share />}
                onClick={() => {
                  navigator.clipboard.writeText(agentDetails?.qr_code);
                  toast.success('Profile QR link copied to clipboard');
                }}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  color: '#6A1B9A',
                  borderColor: '#6A1B9A',
                  width:'45%'
                }}
              >
                Share Digital Card
              </Button>
              <Button
                variant="contained"
                onClick={handleQRDownload}
                startIcon={<Download />}
                sx={{
                  backgroundColor: '#6A1B9A',
                  color: '#fff',
                  borderRadius: '8px',
                  textTransform: 'none',
                  width:'45%'

                }}
              >
                Download Digital Card
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
}
