import React, { useState } from 'react';
import BRANDLOGO from '../Assets/images/brand-logo.png';
import PROFILE from '../Assets/images/nav-profile.png';
import NOTIFY from '../Assets/images/nav-nofication.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import './navbar.css';
import {
  ADD_PROPERTY,
  DASHBOARD,
  HOME,
  MESSAGES,
} from '../constants/route-path';
import { Avatar, Box, IconButton, Popover, Typography } from '@mui/material';
import HomeSidebar from './home-sidebar';
import MENU from '../Assets/images/menu.png';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconRosetteDiscountCheckFilled } from '@tabler/icons-react';
import { handleProfileRedirection } from '../constants/enums';

const Navbar = ({ handleLogout, selectedNavLink, setSelectedNavLink }) => {
  const { pathname } = useLocation();
  const { userAvatar, userName, userId, isUserVerified } = useSelector(
    (state) => state.userDetails || {}
  );

  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navLinks = [
    {
      title: 'Home',
      id: 1,
      to: HOME,
    },
    {
      title: 'Dashboard',
      id: 2,
      to: DASHBOARD,
    },
    {
      title: 'Messages',
      id: 3,
      to: MESSAGES,
    },
  ];

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDrawerToggle = () => setOpenSidebar(true);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <nav className="navbar">
        <div className="nav-brand">
          <img
            src={BRANDLOGO}
            alt="logo"
            className="nav-brand-img"
            onClick={() => navigate(HOME)}
          />
        </div>
        <div className="nav-links-main">
          <div className="nav-links-content">
            {navLinks.map((item) => (
              <NavLink
                onClick={() => setSelectedNavLink(item.id)}
                to={item.to}
                className={`nav-links ${
                  selectedNavLink === item.id ? 'active-nav-link' : ''
                }`}
              >
                {' '}
                {item.title}{' '}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="nav-buttons">
          {pathname !== ADD_PROPERTY && (
            <span
              className="nav-list-property-btn"
              onClick={() => navigate('/add-property')}
            >
              <AddIcon className="nav-add-icon" />
              List my Property
            </span>
          )}

          <span className="nav-button">
            <img src={NOTIFY} alt="notification" />
          </span>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
              padding: '8px 10px',
              borderRadius: '12px',
              marginLeft: '.5rem',
              cursor: 'pointer',
              width: '50%',
            }}
            onClick={handleClick}
            aria-describedby={id}
          >
            <Avatar src={userAvatar} sx={{ width: '36px', height: '36px' }} />

            <Typography sx={{ color: '#474747' }}>{userName}</Typography>
            <KeyboardArrowDownIcon sx={{ color: '#474747' }} />
          </Box>

          <Popover
            id={id}
            transition
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            sx={{
              '.MuiBox-root': {
                border: 'none',
                borderRadius: '10px',
                boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
                cursor: 'pointer',
              },
            }}
          >
            <Box
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              Partner ID : {userId}
              {isUserVerified === 'true' && (
                <IconRosetteDiscountCheckFilled
                  color="#600DB4"
                  width={'20px'}
                  height={'20px'}
                />
              )}
            </Box>
            <Box
              onClick={() => {
                handleClose();
                handleProfileRedirection(navigate);
              }}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Profile
            </Box>
            <Box
              onClick={() => handleLogout()}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Logout
            </Box>
          </Popover>
        </div>
      </nav>

      <div className="home-open-drawer">
        <span className="nav-button">
          <img src={NOTIFY} alt="notification" />
        </span>
        <span
          className="nav-button"
          onClick={handleClick}
          aria-describedby={id}
        >
          <img src={PROFILE} alt="profile" />
        </span>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            display: 'none',
            '.MuiBox-root': {
              border: 'none',
              borderRadius: '10px',
              boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
              cursor: 'pointer',
              alignItems: 'center',
              width: '100%',
            },
          }}
          className="home-profile-content"
        >
          <Box
            onClick={() => handleLogout()}
            sx={{
              borderBottom: '1px solid #D2D2D2 ',
              p: 2,
              bgcolor: 'background.paper',
              cursor: 'pointer',
            }}
          >
            Partner Id : 123456 <ContentCopyIcon sx={{ color: '#474747' }} />
          </Box>
          <Box
            onClick={() => handleLogout()}
            sx={{
              border: 1,
              p: 2,
              bgcolor: 'background.paper',
              cursor: 'pointer',
            }}
          >
            Logout
          </Box>
        </Popover>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="home-menu-icon"
          onClick={handleDrawerToggle}
          sx={{ mx: 2, display: { xmd: 'none' } }}
        >
          <img src={MENU} alt="Menu" style={{ width: 25, height: 25 }} />
        </IconButton>
      </div>

      <HomeSidebar open={openSidebar} setOpen={setOpenSidebar} />
    </>
  );
};

export default Navbar;
