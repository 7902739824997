import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Chip, Grid, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import './viewprofile.css';
import CircleIcon from '@mui/icons-material/Circle';
import UpdateProfile from '../UpdateProfile/UpdateProfile';
import Placeholder from '../Placeholder/Placeholder';
import apis from '../../../Services/api-services';
import GenerateQRModal from './components/GenerateQR';
import dayjs from 'dayjs';
import LoaderComponent from '../../../components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { numDifferentiation } from '../../../Utils/helperUtils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AgentProfilePicture from '../components/AgentProfilePicture';
import { userActions } from '../../../Store/userSlice';
import PropertyDetail from '../../../components/propertyDetail';
import { handleProfileRedirection } from '../../../constants/enums';

const ProfileStat = ({ label, value }) => (
  <Box>
    <Typography sx={{ color: '#600DB4', fontSize: '24px', fontWeight: '600' }}>
      {value}
    </Typography>
    <Typography sx={{ color: '#474747', fontSize: '14px', fontWeight: '500' }}>
      {label}
    </Typography>
  </Box>
);

const ChipGroup = ({ city, areas, backgroundColor }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1rem',
    }}
  >
    <Typography
      sx={{
        color: '#474747',
        fontSize: '16px',
        fontWeight: '500',
        width: '23%',
      }}
    >
      {city}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '75%',
      }}
    >
      {areas?.map((area, index) => (
        <Chip
          key={area}
          label={area}
          sx={{
            background: backgroundColor,
            color: '#474747',
            marginLeft: index ? '.5rem' : 0,
            marginBottom: '.5rem',
          }}
        />
      ))}
    </Box>
  </Box>
);

const LanguageBox = ({ symbol, language }) => (
  <Typography
    sx={{
      color: '#474747',
      fontSize: '14px',
      fontWeight: '500',
      marginLeft: '.5rem',
    }}
  >
    {language} <span style={{ color: '#D2D2D2' }}>|</span>
  </Typography>
);

const testimonial = [
  {
    remark:
      'Suresh is an outstanding Partner, demonstrating both professionalism and remarkable efficiency in his work. With his assistance, I was able to find my dream house in just 15 days',
    name: 'Aishwarya',
  },
  {
    remark:
      'Suresh is an outstanding Partner, demonstrating both professionalism and remarkable efficiency in his work. With his assistance, I was able to find my dream house in just 15 days',
    name: 'Aishwarya',
  },
];

const ViewProfile = ({ customerId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [selectedPropertyID, setSelectedPropertyID] = useState(null);
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);

  const agentId =
    new URLSearchParams(location.search).get('customer_id') || customerId;

  const { userId } = useSelector((state) => state.userDetails || {});

  const [loader, setLoader] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [showGenerateQRModal, setShowGenerateQRModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [properties, setProperties] = useState(null);

  const MAX_LENGTH = 280;
  const areasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  useEffect(() => {
    if (agentId) {
      getAgentDetailsById(agentId);
    } else {
      getAgentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    !agentId && handleProfileRedirection(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgentDetails = async () => {
    setLoader(true);
    const response = await apis.agent.getAgentProfileDetails();

    const agent = response?.data?.profile;
    setAgentDetails(agent);

    dispatch(userActions.setUserId(agent?.customer_id));
    dispatch(userActions.setUserName(agent?.full_name));
    dispatch(userActions.setIsUserVerified(agent?.is_verified));
    dispatch(userActions.setUserAvatar(agent?.profile_picture || ''));

    if (response?.data?.profile?.app_user_id) {
      const response = await apis.property.getListingProperties();
      setProperties(response?.data?.properties);
    }

    setLoader(false);
  };

  const getAgentDetailsById = async (agentId) => {
    setLoader(true);
    const response = await apis.agent.viewAgentProfileQR({
      agentId,
    });

    const profile = response?.data?.profile;
    setAgentDetails(response?.data?.profile);

    if (profile?.customer_id) {
      const response = await apis.agent.getPropertiesByAgentId(
        profile?.customer_id
      );
      setProperties(response?.data?.properties);
    }

    setLoader(false);
  };

  const updateAgentProfilePicture = async (image) => {
    setLoader(true);

    const form = new FormData();
    form.append('profile_picture', image);

    const response = await apis.agent.setAgentProfilePicture(form);

    if (response?.data) {
      toast.success('Profile picture updated successfully');
      await getAgentDetails();
    }
    setLoader(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  // const deleteAgentProfilePicture = async () => {
  //   setLoader(true);

  //   const response = await apis.agent.deleteAgentProfilePicture();
  //   if (response?.data) {
  //     toast.success('Profile picture deleted successfully');
  //   }

  //   setLoader(false);
  // };

  const handleSelectedProperty = (id, target) => {
    if (target?.id !== 'property-item-save') {
      setSelectedPropertyID(id);
      setOpenPropertyDetail(true);
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}

      <Box sx={{ width: '90%', margin: '2rem auto' }}>
        <Box
          className={`hero-section ${
            customerId ? 'sidebar-profile-container' : ''
          }`}
        ></Box>
        <Box
          sx={{
            position: 'absolute',
            top: customerId ? '12%' : '25%',
            right: '8%',
          }}
        >
          <Typography sx={{ color: '#fff' }}>
            Joined v<b>RE</b>fyd on:{' '}
            <b>{dayjs(agentDetails?.created_at).format('DD MMM, YYYY')}</b>
            <Typography sx={{ color: '#fff', marginTop: '20px' }}>
            Partner ID :- {agentDetails?.customer_id}
            </Typography>
          </Typography>
        </Box>

        <AgentProfilePicture
          userId={userId}
          agentDetails={agentDetails}
          updateAgentProfilePicture={updateAgentProfilePicture}
        />

        <Grid
          container
          sx={{ justifyContent: 'space-between', marginTop: '6rem' }}
        >
          {/* Left side */}
          <Grid item container lg={6.8}>
            <Grid item lg={12} className="box">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: '#474747', fontSize: '1.5rem' }}>
                    {agentDetails?.full_name}
                  </Typography>
                  {agentDetails?.is_verified && (
                    <VerifiedIcon
                      sx={{ color: '#600DB4', marginLeft: '.5rem' }}
                    />
                  )}
                </Box>

                {agentDetails?.customer_id === userId && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="outlined"
                      className="edit-profile-btn"
                      onClick={() => setOpenEditModal(true)}
                    >
                      Edit Profile
                    </Button>
                    <Button
                      variant="contained"
                      className="share-profile-btn"
                      onClick={() => setShowGenerateQRModal(true)}
                    >
                      Share Profile
                    </Button>
                  </Box>
                )}
              </Box>
              <Typography sx={{ color: '#7D7D7D', fontSize: '1rem' }}>
                {agentDetails?.city ? agentDetails?.city + ', ' : ''}
                {agentDetails?.state ? agentDetails?.state : ''}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#474747',
                  marginTop: '1rem',
                  textAlign: ' justify',
                }}
              >
                {isExpanded
                  ? agentDetails?.user_description
                  : agentDetails?.user_description?.slice(0, MAX_LENGTH)}
                {agentDetails?.user_description?.length > MAX_LENGTH && (
                  <>
                    {!isExpanded && '...'}

                    <a
                      href="##"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsExpanded(!isExpanded);
                      }}
                      style={{
                        textDecoration: 'none',
                        color: '#7D7D7D',
                        marginLeft: '5px',
                      }}
                    >
                      {isExpanded ? 'see less' : 'see more'}
                    </a>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item lg={12} className="box">
              <Typography
                sx={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '500' }}
              >
                Areas of Operation
              </Typography>

              {areasOfOperation?.map((area) => (
                <ChipGroup
                  city={area.city}
                  areas={area.places || []}
                  backgroundColor="#F5EDFF"
                />
              ))}
            </Grid>
          </Grid>

          {/* Right side */}
          <Grid item lg={5} sx={{ marginTop: '-4rem' }}>
            <Grid item lg={12} className="box">
              <Typography
                sx={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '500' }}
              >
                Info
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '1rem',
                  flexWrap: 'wrap',
                  gap: 2,
                }}
              >
                <ProfileStat
                  label="Years of Experience"
                  value={agentDetails?.years_of_experience || '-'}
                />
                <ProfileStat
                  label="Properties Listed"
                  value={agentDetails?.properties_count || '-'}
                />
                <ProfileStat
                  label="Closed Deals"
                  value={agentDetails?.closed_deals || '-'}
                />
              </Box>
            </Grid>

            <Grid item lg={12} className="box">
              <Typography
                sx={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '500' }}
              >
                My Specialization
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '1rem',
                  flexWrap: 'wrap',
                }}
              >
                {agentDetails?.specialization?.map((specialty, index) => (
                  <React.Fragment key={specialty}>
                    <Box sx={{ marginBottom: '4px' }}>
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        {specialty}
                      </Typography>
                    </Box>
                    {index < 2 && (
                      <Typography sx={{ color: '#D2D2D2' }}>|</Typography>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>

            <Grid item lg={12} className="box">
              <Typography
                sx={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '500' }}
              >
                Language
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '1rem',
                  flexWrap: 'wrap',
                }}
              >
                {agentDetails?.languages?.map((language) => (
                  <LanguageBox key={language} language={language} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* active listing */}
        <Box className="box">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                color: '#474747',
                fontSize: '20px',
                fontWeight: '500',
                margin: '1rem 0',
              }}
            >
              Active Listings
            </Typography>

            {agentDetails?.properties_count === 0
              ? agentDetails?.customer_id === userId && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '6px',
                      width: '20%',
                      height: '40px',
                    }}
                    onClick={() => navigate('/add-property')}
                  >
                    Start Listing
                  </Button>
                )
              : !customerId && (
                  <Button
                    variant="text"
                    className="view-all-btn"
                    onClick={() => navigate('/dashboard')}
                  >
                    View all
                  </Button>
                )}
          </Box>

          {agentDetails?.properties_count === 0 && (
            <Typography
              sx={{
                color: '#474747',
                fontSize: '14px',
                fontWeight: '400',
                marginBottom: '1.5rem',
              }}
            >
              No Active listings found, How about creating a listing right now?
            </Typography>
          )}

          <Grid container gap={2} justifyContent="flex-start">
            {properties?.length > 0 ? (
              properties?.map((item) => (
                <Grid
                  item
                  lg={customerId ? 5.8 : 2.8}
                  md={3.8}
                  sm={12}
                  xs={12}
                  sx={{
                    borderRadius: '10px',
                    position: 'relative',
                    cursor: 'pointer',
                    width: '100%',
                  }}
                  onClick={(e) => {
                    console.log(item, 'item');

                    handleSelectedProperty(item.property_id, e.target);
                  }}
                >
                  <Box
                    sx={{
                      height: '220px',
                      width: '100%',
                    }}
                  >
                    <img
                      src={item.cover_picture}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                      }}
                      alt="property"
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        color: '#474747',
                        fontSize: '18px',
                        fontWeight: '500',
                        marginTop: '1rem',
                      }}
                    >
                      {item.property_title}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#7D7D7D',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginTop: '.5rem',
                      }}
                    >
                      {item?.locality ? item?.locality + ', ' : ''}
                      {item?.city ? item?.city : ''}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: 'white',
                      position: 'absolute',
                      top: '50%',
                      right: '5%',
                      padding: '4px .5rem ',
                      borderRadius: '6px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#600DB4',
                        fontSize: '20px',
                        fontWeight: '500',
                      }}
                    >
                      {numDifferentiation(item.sale_price || 0)}
                    </Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              <Placeholder />
            )}
          </Grid>
        </Box>

        {/*  Recent closed - remove the false condition later */}
        {false && (
          <Box className="box">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{ color: '#474747', fontSize: '20px', fontWeight: '500' }}
              >
                Recent closed on{' '}
                <span style={{ color: '#600DB4' }}>vREfyd</span>
              </Typography>

              {agentDetails?.properties_count === 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/dashboard')}
                  sx={{
                    textTransform: 'capitalize',
                    width: '20%',
                    borderRadius: '6px',
                  }}
                >
                  Find a property
                </Button>
              ) : (
                <Button
                  variant="text"
                  className="view-all-btn"
                  onClick={() => navigate('/dashboard')}
                >
                  View all
                </Button>
              )}
            </Box>

            {agentDetails?.closed_deals === 0 && (
              <Typography
                sx={{
                  color: '#474747',
                  fontSize: '14px',
                  fontWeight: '400',
                  marginBottom: '1.5rem',
                }}
              >
                All the deals closed on vREfyd will appear here automatically.
              </Typography>
            )}
            <Grid container columnGap={2} sx={{ alignItems: 'center' }}>
              {properties?.length > 0 ? (
                properties?.map((item) => (
                  <>
                    <Grid
                      item
                      lg={3}
                      sx={{ borderRadius: '10px', position: 'relative' }}
                    >
                      <img
                        src={item.cover_picture}
                        style={{ width: '100%', borderRadius: '8px' }}
                        alt="property"
                      />
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '20px',
                          fontWeight: '500',
                        }}
                      >
                        {item.property_title}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#7D7D7D',
                          fontSize: '16px',
                          fontWeight: '400',
                        }}
                      >
                        {item?.locality ? item?.locality + ', ' : ''}
                        {item?.city ? item?.city : ''}
                      </Typography>
                      <Box
                        sx={{
                          background: 'white',
                          position: 'absolute',
                          top: '50%',
                          right: '5%',
                          padding: '4px .5rem ',
                          borderRadius: '6px',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#600DB4',
                            fontSize: '20px',
                            fontWeight: '500',
                          }}
                        >
                          ₹ {item.sale_price}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ))
              ) : (
                <Placeholder />
              )}
            </Grid>
          </Box>
        )}

        {/* testmonials */}
        {false && (
          <Box className="box" sx={{ border: '1px solid #F5EDFF' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: '#474747',
                    fontSize: '1.5rem',
                    fontWeight: '500',
                  }}
                >
                  Testimonial
                </Typography>

                {testimonial?.length < 1 && (
                  <Typography
                    sx={{
                      color: '#474747',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginBottom: '1.5rem',
                    }}
                  >
                    Your clients feedback will be displayed here.
                  </Typography>
                )}
              </Box>
              {testimonial?.length < 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'capitalize',
                    borderRadius: '6px',
                    width: '20%',
                  }}
                >
                  Share Feedback Form
                </Button>
              )}
            </Box>

            <Grid container columnGap={2}>
              {testimonial?.map((item) => (
                <Grid
                  item
                  lg={4}
                  sx={{
                    background: '#F8F8F8',
                    padding: '.5rem 1rem',
                    borderRadius: '8px',
                    marginTop: '1rem',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#474747',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    "{item?.remark}"
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      columnGap: 1.2,
                    }}
                  >
                    <Avatar sx={{ width: 24, height: 24 }}>A</Avatar>
                    <Typography sx={{ color: '#600DB4', fontSize: '14px' }}>
                      {item.name}
                    </Typography>
                    <Typography sx={{ color: '#7D7D7D', fontSize: '12px' }}>
                      <CircleIcon sx={{ fontSize: '6px' }} /> 4 days ago
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {openEditModal && (
          <UpdateProfile
            open={openEditModal}
            agentDetails={agentDetails}
            getAgentDetails={() => getAgentDetails()}
            onClose={() => setOpenEditModal(false)}
            updateAgentProfilePicture={updateAgentProfilePicture}
          />
        )}
      </Box>

      <GenerateQRModal
        open={showGenerateQRModal}
        setOpen={setShowGenerateQRModal}
        agentDetails={agentDetails}
      />

      <PropertyDetail
        selectedPropertyID={selectedPropertyID}
        toggleDrawer={toggleDrawer}
        open={openPropertyDetail}
        setOpen={setOpenPropertyDetail}
        from="profile"
        externalAgentId={
          agentDetails?.customer_id === userId
            ? null
            : agentDetails?.customer_id
        }
      />
    </>
  );
};

export default ViewProfile;
